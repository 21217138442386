<template>
    <div>
        <a :href="`https://www.slf.ch/${$i18n.locale}/lawinenbulletin-und-schneesituation.html#lawinengefahr`" target="_blank">
            <img class="m-auto" :src="`https://resources.davos.ch/fileadmin/davos/extensions/davos_forecast/sources/slf/bki_${$i18n.locale}_c.png`" />
        </a>
    </div>
</template>

<script>
export default {
    name: 'Avalanches',
}
</script>
