<template>
  <div v-if="integrations_spa && integrations_pool" class="grid md:grid-cols-2 gap-6">
    <div class="max-w-7xl mx-auto rounded-lg shadow-sw-1 overflow-hidden">
      <div class="bg-white px-4 py-5 border-b border-gray-200 sm:px-6 flex items-center">
        <img src="https://resources.davos.ch/typo3conf/ext/davos_website/Resources/Public/Assets/Svg/Reports/pool.svg" alt="" class="mx-2" />
        <h3 class="font-sans-bold m-0 flex-1">
          {{ $t("icepool.erlebnissbad")}}
        </h3>
        <span class="inline-flex items-center justify-center uppercase px-2 py-1 text-xs font-sans-bold leading-none rounded-full tracking-wider " :class="[integrations_pool.status == 'open' ? 'bg-green-500 text-green-100' : 'bg-red-500 text-red-100']">{{integrations_pool.status}}</span>
      </div>
      <ul role="list" class="divide-y divide-gray-200">
        <li>
          <div class="px-4 py-4 sm:px-6 font-sans flex items-center">

            <span class="block w-10 h-10 bg-gray-200 rounded-full font-sans-bold text-center leading-10">{{eau_la_la.pool}}</span>

            <span class="flex-1 pl-4">{{ $t("icepool.erlebnissbad_text")}}</span>
          </div>
        </li>
      </ul>
    </div>
    <div class="max-w-7xl mx-auto rounded-lg shadow-sw-1 overflow-hidden">
      <div class="bg-white px-4 py-5 border-b border-gray-200 sm:px-6 flex items-center">
        <img src="https://resources.davos.ch/typo3conf/ext/davos_website/Resources/Public/Assets/Svg/Reports/spa.svg" alt="" class="mx-2" />
        <h3 class="font-sans-bold m-0 flex-1">
          {{ $t("icepool.wellness")}}
        </h3>
        <span class="inline-flex items-center justify-center uppercase px-2 py-1 text-xs font-sans-bold leading-none rounded-full tracking-wider " :class="[integrations_spa.status == 'open' ? 'bg-green-500 text-green-100' : 'bg-red-500 text-red-100']" >{{integrations_spa.status}}</span>
      </div>
      <ul role="list" class="divide-y divide-gray-200">
        <li>
          <div class="px-4 py-4 sm:px-6 font-sans flex items-center">
            <span class="block w-10 h-10 bg-gray-200 rounded-full font-sans-bold text-center leading-10">{{eau_la_la.sauna}}</span>
            <span class="flex-1 pl-4">{{ $t("icepool.wellness_text")}}</span>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>

import axios from "axios";

export default {
  name: 'Pool',
  props: {
    page: Object,
  },
  data() {
    return {
      //eau_la_la: null,
      integrations_spa : null,
      integrations_pool : null
    }
  },
  mounted() {

    // integrations
    axios.get(process.env.VUE_APP_API_URL+'typo3temp/feed_dk/integrations.json').then(response => {
      this.integrations = response.data
      this.integrations_spa = response.data.find(item => item.type_integration === "spa");
      this.integrations_pool = response.data.find(item => item.type_integration === "pool");
    })

    axios.get(process.env.VUE_APP_API_URL+'typo3temp/feed_dk/eau_la_la.json').then(response => {
      this.eau_la_la = response.data
    })

  },
}
</script>
