<template>
    <div id="integrations-guest-card">
        <button :class="!modalIsOpen && !qrOverlayIsOpen ? '' : 'hidden'"
            class="inline-flex items-center justify-center px-4 py-2 border border-transparent font-sans-bold rounded-full transition duration-200 ease-in-out mt-2 tracking-widest bg-green-500 text-white w-full md:w-auto"
            @click="setModalIsOpen(true)"
        >
            <span class="flex items-center justify-center">
                <svg class="h-3 w-3 m-auto mr-3">
                    <use xlink:href="#icon--plus"></use>
                </svg>
                {{ $t('guestcard.activate') }}
            </span>
        </button>
        <div class="mt-10" :class="!modalIsOpen && !qrOverlayIsOpen ? '' : 'hidden'">
            <swiper
                class="relative w-full pt-2 px-2 -mx-2 -mt-2 pb-12"
                :slides-per-view="1.05"
                :space-between="15"
                :breakpoints="{
                    640: {
                        slidesPerView: 2.25,
                        spaceBetween: 25,
                    },
                    1200: {
                        slidesPerView: 3.25,
                        spaceBetween: 25,
                    },
                }"
            >
                <swiper-slide v-for="(guestcard, index) in guestcards" :key="index" class="h-auto">
                    <div class="rounded-lg shadow-sw-1 bg-sw-white relative sw-ticket">
                        <div class="top"></div>
                        <div class="px-6 pt-10">
                            <div class="flex">
                            <span
                                class="inline-flex items-center justify-center uppercase px-2 py-1 text-xs font-sans-bold leading-none rounded-full tracking-wider bg-green-500 text-green-100"
                                v-if="guestcard.card_holder.region"
                                >{{ guestcard.card_holder.region }}</span
                            >

                            <button @click="deleteGuestcard(guestcard)" :title="$t('guestcard.delete')" class="ml-auto text-sw-red">
                                <svg class="h-5 w-5 m-auto">
                                    <use :xlink:href="'#icon--x'"></use>
                                </svg>
                            </button>
                            </div>
                            <h3 class="font-sans-bold text-md mb-0 mt-2 h4">
                                {{ guestcard.card_holder.card_type_name }}
                            </h3>
                            <img class="h-36 w-36 block m-auto" :src="getHttpsUrl(guestcard.qrcode_url)" :alt="guestcard.card_holder.card_type_name" />
                        </div>
                        <div class="flex-1 px-6 flex flex-col justify-between">
                            <div class="flex-1">
                                <div class="block mt-2">
                                    <p class="text-sm">
                                        <b>{{ $t('guestcard.valid') }}</b
                                        ><br />
                                        {{ guestcard.card_holder.valid_from }} - {{ guestcard.card_holder.valid_to }}
                                    </p>
                                    <p class="text-sm">
                                        <b>Name</b><br />
                                        {{ guestcard.card_holder.name }} {{ guestcard.card_holder.lastname }}<br />
                                        {{ guestcard.card_holder.birth_day }}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="pb-6 flex justify-between px-6 mt-2">
                            <div>
                                <img class="h-10" :src="getHttpsUrl(guestcard.barcode_url)" :alt="guestcard.card_holder.card_type_name" />
                                <span class="w-48 text-sm">CardNr: {{ guestcard.card_holder.card_number }}</span>
                            </div>
                            <div>
                                <a :href="getHttpsUrl(guestcard.wallet_url)" >
                                    <img src="/images/add-to-wallet-logo.png" class="h-10" alt="" />
                                </a>
                            </div>
                        </div>
                    </div>
                </swiper-slide>
            </swiper>
        </div>

       <div class="" :class="qrOverlayIsOpen? '' : 'hidden'" >
            <div
                class="inline-flex items-center justify-center pl-2 pr-4 py-2 border border-transparent font-sans-bold rounded-full transition duration-200 ease-in-out mt-2 mb-6 tracking-widest bg-green-500 text-white w-full md:w-auto"
                @click="setQrOverlayIsOpen(false)"
            >
                <svg class="h-5 w-5 mr-3 text-white"><use xlink:href="#icon--x"></use></svg> {{ $t('guestcard.back') }}
            </div>
            <div class="text-center">
                <p class="font-sans-extrabold">{{ $t('guestcard.scan') }}</p>
                <p>{{ $t('guestcard.qr_info') }}</p>
            </div>
            <div class="w-2/3 mx-auto relative p-8">
                <svg class="h-5 w-5 text-sw-blue z-50 absolute top-0 left-0 transform rotate-45">
                    <use xlink:href="#icon--chevron-left"></use>
                </svg>
                <svg class="h-5 w-5 text-sw-blue z-50 absolute top-0 right-0 transform rotate-135">
                    <use xlink:href="#icon--chevron-left"></use>
                </svg>
                <svg class="h-5 w-5 text-sw-blue z-50 absolute bottom-0 right-0 transform rotate-225">
                    <use xlink:href="#icon--chevron-left"></use>
                </svg>
                <svg class="h-5 w-5 text-sw-blue z-50 absolute bottom-0 left-0 transform rotate-315">
                    <use xlink:href="#icon--chevron-left"></use>
                </svg>
                <qr-stream @decode="onDecode"></qr-stream>
            </div>
       </div>

       <div class="" :class="modalIsOpen && !qrOverlayIsOpen ? '' : 'hidden'">
                <div :class="!cardAddedSucessfully ? '' : 'hidden'"
                    class="inline-flex items-center justify-center pl-2 pr-4 py-2 border border-transparent font-sans-bold rounded-full transition duration-200 ease-in-out mt-2 mb-6 tracking-widest bg-green-500 text-white w-full md:w-auto"
                    @click="setModalIsOpen(false)"
                >
                    <svg class="h-5 w-5 text-white"><use xlink:href="#icon--chevron-left"></use></svg>
                    {{ $t('guestcard.button_back_list')}}
                </div>

                <div class="relative w-full pt-2 px-2 -mx-2 -mt-2 pb-12">
                    <h2 class="font-sans-extrabold mb-4" :class="'text-'.color">{{ $t('guestcard.activate') }}</h2>

                    <div v-if="serverMessage && !cardAddedSucessfully" class="bg-sw-red px-4 pt-4 pb-2 rounded-lg my-4">
                        <p class="text-white">{{ serverMessage }}</p>
                    </div>

                    <div v-if="cardAddedSucessfully">
                        <div class="bg-sw-green px-4 pt-4 pb-2 mt-4 mb-8 bg-opacity-30 border-sw-green border">
                            <p class=""><strong>{{ $t('guestcard.successfully_added') }}</strong></p>
                        </div>

                        <div class="sm:flex md:flex-row sm:flex-col">
                            <button
                                @click="resetAndListModal()"
                                class="inline-flex items-center justify-center md:w-6/12 w-full h-12 md:mb-1 mb-4 pl-2 pr-4 py-2 border border-transparent font-extrabold rounded-full transition duration-200 ease-in-out bg-green-500 text-white"
                            >
                                <svg class="h-5 w-5 text-white">
                                    <use xlink:href="#icon--chevron-left"></use>
                                </svg>
                                <span>{{ $t('guestcard.button_back_list_success') }}</span>
                            </button>
                            <button
                                @click="resetModal"
                                class="inline-flex items-center justify-center md:w-6/12 w-full h-12 px-3 py-2 border border-transparent font-extrabold rounded-full transition duration-200 ease-in-out bg-sw-blue text-white md:ml-4 sm:ml-0"
                            >
                                <span>{{ $t('guestcard.add_more') }}</span>
                            </button>
                        </div>
                    </div>

                    <form v-if="!cardAddedSucessfully" @submit.prevent="onSubmit" :disabled="isLoading">
                        <div :class="(v$.newCard.card_number.$errors.length ? 'error ' : '') + 'form-group'">
                            <label for="card_number" class="font-bold block mb-1">{{ $t('guestcard.card_number') }}</label>
                            <div class="flex items-center">
                                <input id="card_number" type="text" class="form-control" v-model="newCard.card_number" />
                                <button
                                    type="button"
                                    @click="setQrOverlayIsOpen(true)"
                                    class="flex-1 ml-4 inline-flex items-center justify-center px-3 py-2 border border-transparent font-extrabold rounded-full transition duration-200 ease-in-out bg-sw-blue text-white w-full"
                                >
                                    <svg class="flex-shrink-0 h-4 w-5">
                                        <use xlink:href="#icon--qr"></use>
                                    </svg>
                                </button>
                            </div>
                            <p class="mt-1 text-sw-red text-sm" v-if="v$.newCard.card_number.$errors.length">{{ $t('guestcard.field_required') }}</p>
                        </div>
                        <div class="grid grid-cols-2">
                            <div :class="(v$.newCard.valid_from.$errors.length ? 'error ' : '') + 'form-group flex-1'">
                                <label for="valid_from" class="font-bold block mb-1">{{ $t('guestcard.anreise') }}</label>
                                <flat-pickr :config="flatPickrOptions" id="valid_from" class="form-control" v-model="newCard.valid_from"></flat-pickr>
                                <p class="mt-1 text-sw-red text-sm" v-if="v$.newCard.valid_from.$errors.length">
                                    {{ $t('guestcard.field_required') }}
                                </p>
                            </div>
                            <div :class="(v$.newCard.valid_to.$errors.length ? 'error ' : '') + 'form-group flex-1 ml-2'">
                                <label for="valid_to" class="font-bold block mb-1">{{ $t('guestcard.abreise') }}</label>
                                <flat-pickr :config="flatPickrOptions" id="valid_to" class="form-control" v-model="newCard.valid_to"></flat-pickr>
                                <p class="mt-1 text-sw-red text-sm" v-if="v$.newCard.valid_to.$errors.length">{{ $t('guestcard.field_required') }}</p>
                            </div>
                        </div>
                        <div :class="(v$.newCard.firstname.$errors.length ? 'error ' : '') + 'form-group'">
                            <label for="firstname" class="font-bold block mb-1">{{ $t('guestcard.firstname') }}</label>
                            <input id="firstname" type="text" class="form-control" v-model="newCard.firstname" />
                            <p class="mt-1 text-sw-red text-sm" v-if="v$.newCard.firstname.$errors.length">{{ $t('guestcard.field_required') }}</p>
                        </div>
                        <div :class="(v$.newCard.lastname.$errors.length ? 'error ' : '') + 'form-group'">
                            <label for="lastname" class="font-bold block mb-1">{{ $t('guestcard.lastname') }}</label>
                            <input id="lastname" type="text" class="form-control" v-model="newCard.lastname" />
                            <p class="mt-1 text-sw-red text-sm" v-if="v$.newCard.lastname.$errors.length">{{ $t('guestcard.field_required') }}</p>
                        </div>
                        <div :class="(v$.newCard.birth_day.$errors.length ? 'error ' : '') + 'form-group'">
                            <label for="birth_day" class="font-bold block mb-1">{{ $t('guestcard.birthday') }}</label>

                            <div class="inline-block relative mb-3 pr-5 w-3/12">
                              <select id="birth_days"  class="form-control " v-model ="selected_birth.day"  >
                                <option v-for = "day in listOfDays()" :key="day" :value="day.value" >{{day.label}}</option>
                              </select>
                            </div>
                            <div class="inline-block relative mb-3 pr-5 w-6/12">
                              <select id="birth_month"  class="form-control " v-model ="selected_birth.month"  >
                                <option v-for = "month in listOfMonths()" :key="month" :value="month.value" >{{month.label}}</option>
                              </select>
                            </div>
                            <div class="inline-block relative mb-3  w-3/12">
                              <select id="birth_year"  class="form-control " v-model ="selected_birth.year" >
                                <option v-for = "year in listOfYears()" :key="year" :value="year.value" >{{year.label}}</option>
                              </select>
                            </div>
                            <p class="mt-1 text-sw-red text-sm" v-if="v$.newCard.birth_day.$errors.length">{{ $t('guestcard.field_required') }}</p>
                        </div>
                        <button
                            :disabled="isLoading"
                            type="submit"
                            class="inline-flex items-center justify-center mt-6 px-3 py-2 border border-transparent font-extrabold rounded-full transition duration-200 ease-in-out bg-sw-blue text-white w-full"
                        >
                            <div class="flex items-center tracking-widest">
                                <pulse-loader :loading="isLoading" color="#0898CA"></pulse-loader>
                                <strong><span>{{ $t('guestcard.add') }}</span></strong>
                            </div>
                        </button>
                    </form>
                </div>
            </div>

    </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import 'swiper/swiper-bundle.css'
import { Swiper, SwiperSlide } from 'swiper/vue'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import { German } from 'flatpickr/dist/l10n/de.js'
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import { QrStream } from 'vue3-qr-reader'

export default {
    name: 'ProfileGuestcard',
    components: {
        Swiper,
        SwiperSlide,
        flatPickr,
        PulseLoader,
        QrStream,
    },
    data() {
        return {
            guestcards: [],
            modalIsOpen: false,
            qrOverlayIsOpen: false,
            selected_birth : {
              day : "01",
              month : "01",
              year : 2000,
            },
            newCard: {},
            v$: useVuelidate(),
            flatPickrOptions: {
                locale: 'en',
                dateFormat: 'Y-m-d',
                altInput: true,
                altFormat: 'd.m.Y',
            },
            isLoading: false,
            serverMessage: '',
            cardAddedSucessfully: false,
        }
    },
    validations() {
        return {
            newCard: {
                firstname: {
                    required,
                },
                lastname: {
                    required,
                },
                birth_day: {
                    required,
                },
                card_number: {
                    required,
                },
                valid_from: {
                    required,
                },
                valid_to: {
                    required,
                },
            },
        }
    },
    methods: {
        deleteGuestcard: function(guestcard) {
            if (confirm(this.$t("guestcard.confirm"))) {
                this.guestcards = this.guestcards.filter(card => card.card_holder.card_id !== guestcard.card_holder.card_id)
                localStorage.setItem('guestcards', JSON.stringify(this.guestcards))
            }
        },
        async onSubmit() {
            this.newCard.birth_day = this.selected_birth.year + "-" + this.selected_birth.month + "-" + this.selected_birth.day;
            //console.log(this.newCard.birth_day)
            const result = await this.v$.$validate()


            if (!result) {
                // validation failed
            } else {
                // validation successfull
                this.isLoading = true
                this.serverMessage = ''
                this.cardAddedSucessfully = false

                axios
                    .get('https://buddy.davos.ch/tools/guestcard.php', {
                        params: this.newCard,
                    })
                    .then((result) => {
                        this.cardAddedSucessfully = result.data.success
                        this.serverMessage = result.data.data

                        if (result.data.success) {
                            this.addCard(result.data.data)
                        }
                    })
                    .catch((error) => {
                        this.serverMessage = 'Etwas ist schief gelaufen, bitte versuche es später erneut!'
                        this.cardAddedSucessfully = false
                        console.log(error)
                    })
                    .finally(() => {
                        this.isLoading = false
                        let elm = document.getElementById('integrations-guest-card')
                        const y = elm.getBoundingClientRect().top + window.pageYOffset - 64;
                        window.scrollTo({top: y, behavior: 'smooth'});
                       // elm.scrollIntoView('alignToTop')
                    })
            }
        },
        getHttpsUrl: function (url) {
            return url.replace('http://', 'https://');
        },
        onDecode: function (result) {
            this.newCard.card_number = result.replace("http://resources.davos.ch/dkcard?i=", "")
            this.setQrOverlayIsOpen(false)
        },
        resetModal: function () {
            this.v$.$reset()
            this.newCard = {}
            this.serverMessage = ''
            this.cardAddedSucessfully = false
            this.modalIsOpen = true
        },
        resetAndListModal: function () {
          this.v$.$reset()
          this.newCard = {}
          this.serverMessage = ''
          this.modalIsOpen = false
          this.cardAddedSucessfully = false
        },
        setModalIsOpen: function (value) {
            this.modalIsOpen = value
            if (value){
              let elm = document.getElementById('integrations-guest-card')
              const y = elm.getBoundingClientRect().top + window.pageYOffset - 64;
              window.scrollTo({top: y, behavior: 'smooth'});
            }
        },
        setQrOverlayIsOpen: function (value) {
            this.qrOverlayIsOpen = value
        },
        moment: function () {
            return moment()
        },
        addCard: function (serverData) {
            var guestcards_tmp = localStorage.getItem('guestcards')
            if (guestcards_tmp) {
                this.guestcards = JSON.parse(guestcards_tmp)
            }
            serverData.card_holder.valid_from = moment(serverData.card_holder.valid_from, 'YYYY-MM-DDTHH:mm:ssZ').format('DD.MM.YYYY')
            serverData.card_holder.valid_to = moment(serverData.card_holder.valid_to, 'YYYY-MM-DDTHH:mm:ssZ').format('DD.MM.YYYY')
            serverData.card_holder.birth_day = moment(serverData.card_holder.birth_day, 'YYYY-MM-DDTHH:mm:ssZ').format('DD.MM.YYYY')

            this.guestcards.push(serverData)
            localStorage.setItem('guestcards', JSON.stringify(this.guestcards))
        },
        listOfYears: function(){
          const yearStart = moment().year() -6
          const yearEnd = yearStart - 100
          let listYears = []
          for (let year = yearStart; year > yearEnd; year--) {
            listYears.push({
              'label' : year,
              'value' : year
            });
          }
          return listYears
        },
       listOfMonths: function(){
         let listOfMonths = [];
         for (let month = 0; month <= 11 ; month++) {
           listOfMonths.push({
               'label' : moment().month(month).format("MMMM"),
               'value' : moment().month(month).format("MM")
           });
         }
         if (this.selected_birth.day> moment().year(this.selected_birth.year).month(this.selected_birth.month-1).daysInMonth()) {
           this.selected_birth.day ='01'
         }
         return listOfMonths
       },
       listOfDays:function(){
           let numberOfDays = moment().year(this.selected_birth.year).month(this.selected_birth.month-1).daysInMonth();
           let listOfDays = [];
           for (let day = 1; day <= numberOfDays ; day++) {
             listOfDays.push({
               'label' : day,
               'value' : day<10? '0'+day : day
             });
           }
           return listOfDays
       },
      onChangeSelectedBirth:function(){
         this.newCard.birth_day = this.selected_birth.year + "-" + this.selected_birth.month + "-" + this.selected_birth.day;
      }
    },
    mounted() {
        if (localStorage.getItem('guestcards')) {
            this.guestcards = JSON.parse(localStorage.getItem('guestcards'))
        }
        if (this.$i18n.locale !== 'en') {
            this.flatPickrOptions.locale = German
            moment.locale('de')
        }
        this.selected_birth.year = moment().year()-6
        //this.selected_birth.month = moment().month().format('MM')
        //this.selected_birth.day = moment().day().format('DD')

    },
}
</script>
