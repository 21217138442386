<template>
  <div class="border border-grey-200">
    <div class="grid grid-cols-2 md:grid-cols-3 border-b border-grey-200">
      <div class="p-4 text-md md:text-lg border-r border-grey-200">
        <p class="font-sans-bold flex items-center">Halfpipes <span class="ml-2 inline-block h-2 w-2 rounded-full" :class="[wispo_park.SBDailyHalfpipesOpenedToday.value == 0 ? 'bg-red-500 text-red-100' : 'bg-green-500 text-green-100']"></span></p>
        <p class="text-3xl">{{wispo_park.SBDailyHalfpipesOpenedToday.value}}/{{wispo_park.SBBasisHalfpipes.value}}</p>
      </div>
      <div class="p-4 text-md md:text-lg border-r border-grey-200">
        <p class="font-sans-bold">Snowcross-Pisten <span class="ml-1 inline-block h-2 w-2 rounded-full" :class="[wispo_park.SBDailyBoardercrossOpenedToday.value == 'no' ? 'bg-red-500 text-red-100' : 'bg-green-500 text-green-100']"></span></p>
        <p class="text-3xl">{{wispo_park.SBDailyBoardercrossOpenedToday.value == 'no' ? 0 : wispo_park.SBDailyBoardercrossOpenedToday.value}}/{{wispo_park.SBBasisBoarderCross.value}}</p>
      </div>
      <div class="p-5 text-md md:text-lg col-span-2 border-t border-grey-200 md:col-auto md:border-0">
        <p class="font-sans-bold">Snowpark <span class="ml-1 inline-block h-2 w-2 rounded-full" :class="[wispo_park.SBDailySnowparkOpenToday.value == 'no' ? 'bg-red-500 text-red-100' : 'bg-green-500 text-green-100']"></span></p>
        <p class="text-3xl">{{wispo_park.SBDailySnowparkOpenToday.value == 'no' ? 0 : wispo_park.SBDailySnowparkOpenToday.value}}/{{wispo_park.SBBasisSnowboardparks.value}}</p>
      </div>
    </div>
    <div class="grid grid-cols-2">
      <div class="p-5 text-md md:text-lg border-r border-grey-200">
        <p class="font-sans-bold">Snowtubepisten <span class="ml-1 inline-block h-2 w-2 rounded-full" :class="[wispo_park.SBDailySnowtubeOpenedToday.value == 'no' ? 'bg-red-500 text-red-100' : 'bg-green-500 text-green-100']"></span></p>
        <p class="text-3xl">{{wispo_park.SBDailySnowtubeOpenedToday.value == 'no' ? 0 : wispo_park.SBDailySnowtubeOpenedToday.value}}/{{wispo_park.SBDailySnowtubeTotal.value}}</p>
      </div>
      <div class="p-5 text-md md:text-lg">
        <p class="font-sans-bold">AirBoard-Pisten <span class="ml-1 inline-block h-2 w-2 rounded-full" :class="[wispo_park.SBDailyAirBoardingOpenToday.value == 0 ? 'bg-red-500 text-red-100' : 'bg-green-500 text-green-100']"></span></p>
        <p class="text-3xl">{{wispo_park.SBDailyAirBoardingOpenToday.value}}/{{wispo_park.SBBasisAirBoarding.value}}</p>
      </div>
    </div>

  </div>
</template>

<script>
import axios from "axios";

export default {
  name: 'Snowparks',
  props: {
    appearance: Object,
  },
  data() {
    return {
      wispo_park: [],
      response: [],
    }
  },
  mounted() {
    //console.log(this.appearance.kidsType);
    this.wispo_park = JSON.parse('{"SBBasisHalfpipes":{"label":"Halfpipes offen","value":"1"},"SBDailyHalfpipesOpenedToday":{"label":"Half-pipes open","value":"0"},"SBBasisBoarderCross":{"label":"Anzahl Snowcross-Pisten total","value":"1"},"SBDailyBoardercrossOpenedToday":{"label":"Snowcross-Pisten offen","value":"no"},"SBBasisSnowboardparks":{"label":"Snowpark offen","value":"2"},"SBDailySnowparkOpenToday":{"label":"Snow park open","value":"no"},"SBBasisAirBoarding":{"label":"AirBoard-Pisten total","value":"2"},"SBDailyAirBoardingOpenToday":{"label":"AirBoard-Pisten offen","value":"0"},"SBDailySnowtubeTotal":{"label":"Snowtubepisten total","value":"1"},"SBDailySnowtubeOpenedToday":{"label":"Snowtubepisten offen","value":"no"}}');
    console.log(this.wispo_park);

   axios.get('https://resources.davos.ch/fileadmin/davos/extensions/davos_forecast/json/de/wispo_park.json').then(response => {
      this.response = response;
    });
  },
}
</script>
