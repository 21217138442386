<template>
  <div v-if="outdoorfacilities" class="grid md:grid-cols-2 gap-6">
    <div v-for="outdoorfacility in outdoorfacilities" :key="outdoorfacility" class="max-w-7xl mx-auto rounded-lg shadow-sw-1 overflow-hidden" >
      <div class="bg-white px-4 py-5 border-b border-gray-200 sm:px-6 flex items-center">
        <img src="https://resources.davos.ch/typo3conf/ext/davos_website/Resources/Public/Assets/Svg/Reports/{{ outdoorfacility.icon }}.svg" alt="" class="mx-2" v-if="outdoorfacility.icon!='' && outdoorfacility.icon!='info'"/>
        <svg v-if="outdoorfacility.icon=='' || outdoorfacility.icon=='info'" class="icon icon--info w-6 h-6 mr-2"><use xlink:href="#icon--info"></use></svg>
        <h4 class="font-sans-bold m-0 flex-1">
          {{ outdoorfacility.title }}
        </h4>
        <span class="inline-flex items-center justify-center uppercase px-2 py-1 text-xs font-sans-bold leading-none rounded-full tracking-wider" :class="outdoorfacility.status == 'open' ? 'bg-green-500 text-green-100' : 'bg-red-500 text-red-100'">{{ outdoorfacility.status }}</span>
      </div>
      <ul role="list" class="divide-y divide-gray-200">
        <li>
          <div class="px-4 py-4 sm:px-6 font-sans" v-html="outdoorfacility.description"></div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>

import axios from "axios";

export default {
  name: 'OutdoorFacilities',
  props: {
    page: Object,
    appearance: Object
  },
  data() {
    return {
      outdoorfacilities: null,
    }
  },
  mounted() {
    let filter = ['freeride', 'tobogganrun', 'bikepark', 'ropepark'];
    if(this.appearance.outdoorfactypes){
      filter = this.appearance.outdoorfactypes.split(',');
    }
    axios.get(process.env.VUE_APP_API_URL+`typo3temp/feed_dk/outdoorfacilities_${this.$i18n.locale}.json`).then(response => {
      this.outdoorfacilities = response.data.filter(function (outdoorfacility) {
        if (filter.includes(outdoorfacility.type_integration)) {
          return true;
        }
      });
    })
  },
}
</script>
